'use strict';
/* jshint -W117 */
var siteInit = {
	variables: {

		$window: $(window),
		$html: $('html'),
		$body: $('body'),
		$header: $('.page__header'),
		$logo: $('.logo'),

		_isSmallScreen: false,
		_isMediumScreen: false,
		_isLargeScreen: false,
		_isExtraLargeScreen: false,
		_isPortrait: false,
		_easing: 'Quart.easeOut'
		//_bodyClass: $('body').attr('class')

	},

	DOMready: function() {
		if (window.matchMedia) {
			siteInit.variables._isExtraSmallScreen = (window.matchMedia('(min-width:30em)').matches); // sopra i 480px
			siteInit.variables._isSmallScreen = (window.matchMedia('(min-width:48em)').matches); // sopra i 768px
			siteInit.variables._isMediumScreen = (window.matchMedia('(min-width:92em)').matches); // sopra i 992px
			siteInit.variables._isLargeScreen = (window.matchMedia('(min-width:75em)').matches); // sopra i 1200px
			siteInit.variables._isExtraLargeScreen = (window.matchMedia('(min-width:95em)').matches); // sopra i 1520px
			siteInit.variables._isPortrait = (window.matchMedia('(orientation: portrait)').matches);
		}

		if (siteInit.isTouchDevice() === true) {
	    $('html').addClass('touch'); //your logic for touch device here
  	} else {
  	    $('html').addClass('no-touch'); //your logic for non touch device here
  	}

  	if ($('.js-sidebar').length > 0) {
  		siteInit.initSidebar();
			siteInit.initSidebarNav();
  	}

		if ($('.js-goto').length > 0) {
			siteInit.goTo();
		}

		if ($('.js-counter').length > 0) {
			siteInit.initCounter();
		}

		if ($('.js-navigation').length > 0) {
			siteInit.initMobileMenu();
		}

		if ($('.js-animate').length > 0) {
			siteInit.initAnimate();
		}

		if ($('.js-infinite-scroll').length > 0) {
			siteInit.initInfiniteScroll();
		}

		if ($('.js-toggle').length > 0) {
			siteInit.toggleObj();
		}
		//siteInit.initStripesBg();
		if ($('.js-carousel').length > 0) {
			siteInit.carousel();
		}

		if ($('.box').length > 0) {
			siteInit.hideBox();
		}
	},

	DOMload: function() {

	},

	isTouchDevice: function() {
		return true === ('ontouchstart' in window || window.DocumentTouch && document instanceof DocumentTouch);
	},

	orientation: function() {
		return (siteInit.variables.$window.width() > siteInit.variables.$window.height()) ? 'landscape' : 'portrait';
	},

	initMobileMenu: function() {
		$('.js-navigation').on('click', function(e) {
			e.preventDefault();

			if($(this).hasClass('is-active'))
			{
				$('.navigation').removeClass('is-visible');
				$(this).text('Menù');
			}
			else {
				$('.navigation').addClass('is-visible');
				$(this).text('Chiudi');
			}

			$(this).toggleClass('is-active'); /*.toggleClass('icon--close');*/
		});
	},

	initSidebar: function() {
		$('.js-sidebar__btn').panelslider({

			bodyClass: 'sidebar-right', // Class to be added to body when panel is opened
			clickClose: true,       // If true closes panel when clicking outside it
			onOpen: null            // Callback after the panel opens

		});
	},

	initSidebarNav: function() {
		$('.navigation-sidebar').addClass('is-hide');

		$('.js-trigger-navigation-sidebar').bind('inview', function(event, isInView, visiblePartX, visiblePartY) {
			if (isInView) {
				// element is now visible in the viewport
				if (visiblePartY === 'bottom') {
					$('.navigation-sidebar').addClass('is-hide');
				}
			} else {
				$('.navigation-sidebar').removeClass('is-hide');
			}
		});
	},

	carousel: function() {
		$('.js-carousel').owlCarousel({
			loop: true,
			margin: 10,
			nav: true,
			responsive: {
				0: {
					items: 1
				},
				600: {
					items: 1
				},
				1000: {
					items: 1
				}
			}
		});
	},

	hideBox: function() {
		$('.box').each(function(){
			if ($(this).contents().length < 1) {
				$(this).addClass('hidden');
				//console.log( $('.box').contents() );
			}
		});
		// if ( $('.box').contents() == "" || $('.box').contents() == null ) {
		// }
	},

	goTo: function() {
		$('.js-goto').on('click', function(e){
			e.preventDefault();

			$padding = $(this).data('padding');

			if (($padding) === undefined || ($padding) === null){
				$padding = 0;
			}

			$('.js-goto-anchor').animatescroll({easing: 'easeInOutQuad', padding: $padding });
		});
	},

	toggleObj: function() {
		$('.js-toggle').on('click', function(e) {
			$target = $('#' + $(this).data('toggleTarget'));

			console.log($target);
			if ($target.length > 0) {
				$target.slideToggle();
			}

			e.preventDefault();
		});
	},

	initAnimate: function() {
		$('.js-animate').bind('inview', function(event, isInView) {
			if (isInView) {
				$(this).addClass('js-animate--start');
			}
		});
	},

	initHighlight: function(){
		$('em').bind('inview', function(event, isInView) {
			if (isInView) {
				$(this).addClass('js-animate').addClass('is-highlight');
			}
		});
	},

	initInfiniteScroll: function() {
		var ias = jQuery.ias({
			container: '.js-infinite-scroll',
			item: '.post',
			pagination: '.section__footer',
			next: '.js-infinite-link--prev'

		});

		//ias.extension(new IASSpinnerExtension());
		ias.extension(new IASTriggerExtension({
			offset: 2,
			text: $('.js-infinite-link--prev').data('infinite-scroll-text'),
			html: '<div class="gutter-top gutter-bottom  text-center ias-trigger ias-trigger-next"><button class="button button--primary">{text}</button></div>',
		}));

		ias.extension(new IASSpinnerExtension({
			src: 'https://www.evoluzionetelematica.it/wp-content/themes/evoluzionetelematica/img/loading.gif',
			html: '<div class="gutter-top gutter-bottom text-center ias-spinner"><img src="{src}"/></div>'
		}));
	},

	initStripesBg: function() {
		// LINEMAKERS
		var _linesBG,
			_linesFooter;

		_linesBG = [
			{top: 0, left: '50%', width: 1, height: '100vh', color: '#e1e1e1', hidden: true, animation: { duration: 2000, easing: 'easeInOutExpo', delay: 0, direction: 'TopBottom' }},
		];
		_linesFooter = [
			{top: 0, left: '50%', width: 1, height: '100vh', color: '#2a2a2a', hidden: true, animation: { duration: 2000, easing: 'easeInOutExpo', delay: 0, direction: 'TopBottom' }},
		];

		if (siteInit.variables._isLargeScreen) {
			_linesBG = [
				{top: 0, left: '25%', width: 1, height: '100vh', color: '#e1e1e1', hidden: true, animation: { duration: 2000, easing: 'easeInOutExpo', delay: 0, direction: 'TopBottom' }},
				{top: 0, left: '50%', width: 1, height: '100vh', color: '#e1e1e1', hidden: true, animation: { duration: 2000, easing: 'easeInOutExpo', delay: 0, direction: 'TopBottom' }},
				{top: 0, left: '75%', width: 1, height: '100vh', color: '#e1e1e1', hidden: true, animation: { duration: 2000, easing: 'easeInOutExpo', delay: 0, direction: 'TopBottom' }}
			];

			_linesFooter = [
				{top: 0, left: '25%', width: 1, height: '100vh', color: '#2a2a2a', hidden: true, animation: { duration: 2000, easing: 'easeInOutExpo', delay: 0, direction: 'TopBottom' }},
				{top: 0, left: '50%', width: 1, height: '100vh', color: '#2a2a2a', hidden: true, animation: { duration: 2000, easing: 'easeInOutExpo', delay: 0, direction: 'TopBottom' }},
				{top: 0, left: '75%', width: 1, height: '100vh', color: '#2a2a2a', hidden: true, animation: { duration: 2000, easing: 'easeInOutExpo', delay: 0, direction: 'TopBottom' }}
			];
		}

		var lineMaker = new LineMaker({
			// Where to insert the lines container.
			//element: //the DOM element or a string to specify the selector, e.g. '#id' or '.classname'.
			// position: if fixed the lines container will have fixed position.
			position: 'fixed',
			// The lines settings:
			//
			// top, left, width, height: numerical for pixels or string for % and viewport units. Examples: 2 || '20%' || '50vw'.
			// color: the (bg)color of the line.
			// hidden: defines if the line is rendered initially or hidden by default.
			// animation: animation properties for the line
			//    duration: animation speed.
			//    easing: animation easing (animejs easing. To see all possible values console animejs.easings).
			//    delay: animation delay.
			//    direction: line animation direction. Possible values: TopBottom || BottomTop || LeftRight || RightLeft || CenterV || CenterH.
			lines: _linesBG
		});

		setTimeout(function() {
			lineMaker.animateLinesIn();
		}, 500);


		var lineMakerFooter = new LineMaker({
			// Where to insert the lines container.
			//element: '.page__footer', //the DOM element or a string to specify the selector, e.g. '#id' or '.classname'.
			// element: the DOM element or a string to specify the selector, e.g. '#id' or '.classname'.
			// position: Whether to prepend or append to the parent.element
			// position: if fixed the lines container will have fixed position.
			position: 'absolute',
			parent: {element: '.page__footer', position: 'prepend'},
			// The lines settings:
			//
			// top, left, width, height: numerical for pixels or string for % and viewport units. Examples: 2 || '20%' || '50vw'.
			// color: the (bg)color of the line.
			// hidden: defines if the line is rendered initially or hidden by default.
			// animation: animation properties for the line
			//    duration: animation speed.
			//    easing: animation easing (animejs easing. To see all possible values console animejs.easings).
			//    delay: animation delay.
			//    direction: line animation direction. Possible values: TopBottom || BottomTop || LeftRight || RightLeft || CenterV || CenterH.
			lines: _linesFooter
		});

		setTimeout(function() {
			lineMakerFooter.animateLinesIn();
		}, 500);
	}

};

$(document).ready(function() {
	siteInit.DOMready();
});

/*$(window).load(function () {
  siteInit.DOMload();
});*/

$(window).on('scroll', function() {
	didScroll = true;
});

// Hide menù on on scroll down

var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $('.page__header').outerHeight();

// run hasScrolled() and reset didScroll status
setInterval(function() {
	if (didScroll && siteInit.variables._isSmallScreen) {
		hasScrolled();
		didScroll = false;
	}
}, 250);

function hasScrolled() {
	var st = $(window).scrollTop();

	// Make sure they scroll more than delta
	if (Math.abs(lastScrollTop - st) <= delta) {
		return;
	}

	// If they scrolled down and are past the navbar, add class .nav-up.
	// This is necessary so you never see what is "behind" the navbar.
	if (st > lastScrollTop && st > navbarHeight) {
		// Scroll Down
		$('.page__header').addClass('is-hide').removeClass('is-visible');
	} else {
		// Scroll Up
		if (st + $(window).height() < $(document).height()) {
			$('.page__header').removeClass('is-hide').addClass('is-visible');
		}
		if ($(window).scrollTop() < '120') {
			$('.page__header').removeClass('is-visible');
		}
	}

	lastScrollTop = st;
}
